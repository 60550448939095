import request from '@/utils/request'


// 查询会员特权说明列表
export function listVip(query) {
  return request({
    url: '/biz/vipPrivilege/list',
    method: 'get',
    params: query
  })
}

// 查询会员特权说明分页
export function pageVip(query) {
  return request({
    url: '/biz/vipPrivilege/page',
    method: 'get',
    params: query
  })
}

// 查询会员特权说明详细
export function getVip(data) {
  return request({
    url: '/biz/vipPrivilege/detail',
    method: 'get',
    params: data
  })
}

// 新增会员特权说明
export function addVip(data) {
  return request({
    url: '/biz/vipPrivilege/add',
    method: 'post',
    data: data
  })
}

// 修改会员特权说明
export function updateVip(data) {
  return request({
    url: '/biz/vipPrivilege/edit',
    method: 'post',
    data: data
  })
}

// 删除会员特权说明
export function delVip(data) {
  return request({
    url: '/biz/vipPrivilege/delete',
    method: 'post',
    data: data
  })
}
